.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom */

.primary {
  color: #552735!important;
}

.secondary {
  color: #D29111!important;
}

.is-primary {
  background-color: #552735!important;
}

.is-secondary {
  background-color: #D29111!important;
  color: white!important;
  border: 0
}

a.navbar-item:hover {
  color: #552735!important;
}

.subtitle {
  white-space: pre-line!important;
}

/* Hero */

.hero.has-background {
  position: relative;
  overflow: hidden;
}
.hero-background {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
.hero-background.is-transparent {
  opacity: 0.3;
}


/* Hero with Right Image */


.hero.is-fullheight > .columns {
  flex: 1;
}
.hero.is-fullheight > .columns:last-child {
  margin-bottom: 0;
}

.hero.is-fullheight > .columns > .column {
  display: flex;
}

/* Partner Logos */

.partner-logos {
  max-height: 100px;
  padding: 0 1em;
}

/* MAP */

.map-holder {
  height: 500px;
}